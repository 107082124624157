<template>
  <div class="item">
    <el-col :span="6">
      <div class="leftContent"   @click="jumpDetail(goodsDetails)">
        <img src="@/assets/conpon.jpg" alt="" />
      </div>
      <div class="rightContent">
        <el-col :span="24" class="nameTit">
          <span class="nameBox" :title="goodsDetails.couponName">{{ goodsDetails.couponName }}</span>
          <span class="gift">[赠品]</span>
        </el-col>
        <el-col :span="24" class="specMsg" v-if="goodsDetails.useCondition==0">
          <span>全场商品可用</span>
         </el-col>
          <el-col :span="24" class="specMsg" v-if="goodsDetails.useCondition==1">
          <span>满{{ goodsDetails.useAmountCondition }}元可用</span>
         </el-col>
          <el-col :span="24" class="specMsg" v-if="goodsDetails.useCondition==2">
          <span>满{{ goodsDetails.useNumCondition }}个品种可用</span>
         </el-col>
         <el-col class="dateMsg" >
          <span>开始时间：{{goodsDetails.validStartTime.substring(0,10)}}</span>
        </el-col>
        <el-col :span="24" class="dateMsg" >
          <span>结束时间：{{goodsDetails.validEndTime.substring(0,10)}}</span></el-col
        >
       
      </div>
    </el-col>
    <el-col :span="2" class="text-center goodsBox"  v-if="goodsDetails.promotionMethod==0">
       ￥{{ goodsDetails.promotionValue }}
    </el-col>
    <el-col :span="2" class="text-center goodsBox"  v-if="goodsDetails.promotionMethod==1">
       {{ goodsDetails.promotionValue }}折
    </el-col>

    <el-col :span="2" class="text-center goodsBox">{{
      goodsDetails.tmpCount
    }}</el-col>
    <el-col :span="14" class="text-center goodsBox">赠品优惠券</el-col>
   
  </div>
</template>

<script>
export default {
  name: "GoodsItem",
  data() {
    return {
      indexNum:'',
      couponSelected: "",
      couponAmount:0,
      proSec:''
    };
  },
  props: {
    goodsDetails: {
      type: Object,
    },
    num:{
      type:Number
    },
    allowCoupon:{
      type:Number
    }
  },
  created() {
    console.log('123',this.goodsDetails)
    
  },
  computed: {
  
  },
  methods: {
    jumpDetail(goods) {
      console.log(goods)
    },
   
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.item {
  margin-top: 4px;
  background: rgba(240, 225, 210, 0.2);
  padding: 10px 20px;
  float: left;
  width: calc(100% - 40px);
  .leftContent {
    float: left;
    width: 80px;
    height: 80px;
    margin-top:10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rightContent {
    float: left;
    margin-left: 8px;
    width: calc(100% - 88px);
    .nameTit {
      color: #333;
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 8px;
      .nameBox{
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        display:inline-block;
        max-width:110px;
        font-size: 14px;
      }
      .gift {
        color: #ff3333;
        font-size: 12px;
        vertical-align: super;
      }
    }
    .specMsg {
      font-size: 12px;
      color: #666;
      line-height: 20px;
      margin-bottom: 5px;
      span:first-child {
        margin-right: 5px;
      }
    }
    .dateMsg {
      font-size: 12px;
      color: #666;
      line-height: 20px;
      margin-bottom: 5px;
      span:first-child {
        margin-right: 5px;
      }
    }
  }
  .goodsBox {
    line-height: 80px;
    font-size: 14px;
    color: #666;
  }
  .orderPromotionsBox {
    height: 80px;
    justify-content: center; //子元素水平居中
    align-items: center; //子元素垂直居中
    display: -webkit-flex;
    .orderPromotionsMsg {
      font-size: 14px;
      color: #666;
      text-align: left;
      text-indent: 20px;
    }
  }

  /deep/.el-select {
    height: 30px;
    width: 175px;
    line-height: 30px;
    .el-input__inner {
      height: 30px;
      color: #FFD303;
      border-color: #FFD303;
    }
    .el-input__icon {
      line-height: 30px;
      color: #FFD303;
    }
  }
}
</style>
